.firstLetter {
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  color: white;
}

.text-calypso {
  color: #5dcec3;
}

.logoH {
  height: auto;
  width: 450px;
}

.pHero {
  font-size: 24px;
  font-weight: 200;
  line-height: 36px;
}

.heroContainer {
  max-width: 90%;
}

.img-hero {
  height: auto;
  max-width: 550px;
  margin-top: 10%;
  margin-bottom: 3%;
}

@media screen and (max-width: 768px) {
  .firstLetter {
    font-size: 50px;
  }

  .welcome-intro-responsive {
    margin-top: 100px;
  }

  h1 {
    font-size: 45px;
  }

  .img-hero {
    height: auto;
    max-width: 350px;
  }
}
