a:hover, a:active {
  color: #FFFFFF !important;
}

.pHero {
  font-size: 20px;
  font-weight: 200;
  line-height: 1.5em;
}

.img-beta {
  height: auto;
  max-width: 455px;
  margin-top: 10%;
}

.beta_container_download {
  margin-bottom: 4em;
  width: 75%;
}

.beta_container_copy {
  width: 75%;
  margin-bottom: 1em;
}

.beta_title_container {
  height: auto;
  width: auto;
}

.beta_title {
  line-height: 1em;
  height: auto;
  width: auto;
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 0.3em;
}

.beta_title span, .pHero span, .beta_subtitle2 span {
  color: #FF5A54;
}

.beta_subtitle {
  line-height: 1.3em;
  height: auto;
  width: auto;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 0.3em;
}

.beta_subtitle_container {
  margin-top: 2em;
  margin-bottom: 2em;
}

.beta_subtitle2 {
  line-height: 1em;
  height: auto;
  width: auto;
  font-size: 20px;
  font-weight: 100;
  margin-bottom: 1em;
}

.beta_white {
  color: #FFFFFF;
}

.beta_gray {
  color: #C4C4C4;
}

@media screen and (max-width: 768px) {
  .welcome-intro-responsive {
    margin-top: 100px;
  }

  .img-beta {
    height: auto;
    max-width: 330px;
  }

  .beta_subtitle_container {
    margin-top: 4em;
    margin-bottom: 2em;
  }

  .beta_container_download {
    margin-bottom: 4em;
    width: 100%;
  }
  
  .beta_container_copy {
    width: 100%;
  }
}
