.betaContainer {
  margin-top: 15%;
}

.pBeta {
  font-size: 20px;
  font-weight: 100;
  line-height: 1.3em;
}

.img-beta {
  height: auto;
  max-width: 455px;
  margin-top: 10%;
}

.beta_title_container {
  height: auto;
  width: auto;
}

.beta_titles {
  line-height: 1em;
  height: auto;
  width: auto;
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 0.3em;
}

.beta_titles span, .pBeta span {
  color: #FF5A54;
}

.beta_subtitles {
  line-height: 1em;
  height: auto;
  width: auto;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 1em;
}

.beta_white {
  color: #FFFFFF;
}

.beta_gray {
  color: #C4C4C4;
}

@media screen and (max-width: 768px) {
  .welcome-intro-responsive {
    margin-top: 100px;
  }

  .betaContainer {
    margin-top: 1%;
  }

  .img-beta {
    height: auto;
    max-width: 330px;
  }
}
