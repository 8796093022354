:root {
    --color1: #2b0063;
    --color2: #70edcc;
    --color3: #008db7;
    --color4: #1A1936;
    --color5: #ba007c;
    --color6: #ff5a54;
}


.Button {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    line-height: 24px;
    text-align: center;
    padding: 12px 21px;
    border: 0 none;
    border-radius: 3px;
    outline: 0 none;
    position: relative;
    z-index: 1;
    background: var(--color6);
    /* margin-top: 27px; */
    margin-bottom: 13px;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-block;
}


.Button:hover {
    color: #fff;
}

.Button:focus {
    color: #fff;
}