.logo {
  width: auto;
  height: 50px;
}

.button-navbar > a {
  align-items: center;
  margin: 5px;
  margin-bottom: 1px;
  width: auto;
  height: 47.99px;
  color: #fff;
  border-radius: 3px;
  overflow: hidden;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}