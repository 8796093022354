.message_loading {
  color: white;
}

.success_message_beta {
  background-color: #50cb93;
  color: white;
  font-size: 1em;
  width: fit-content;
  padding: .3em;
  margin-top: -.6em;
  margin-bottom: 1em;
  border-radius: .4em;
}

.error_message_beta {
  background-color: #ff5a54;
  color: white;
  font-size: 1em;
  width: fit-content;
  padding: .3em;
  margin-top: .3em;
  margin-bottom: 1em;
  border-radius: .4em;
}

.form_container_beta {
  margin-top: 1.5em;
  width: 70%;
}

@media screen and (max-width: 768px) {
  .form_container_beta {
      width: 100%;
  }
}
