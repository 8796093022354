.section {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 70px 20px 20px 20px;
}

.container {
  width: 80%;
}

.justify {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.emojiDiv {
  width: 50%;
  height: auto;
}

.emoji {
  font-size: 80px;
}

.plus {
  font-size: 40px;
  color: var(--color7);
}

@media screen and (max-width: 768px) {
  .section {
    padding: 70px 10px 10px 10px;
  }

  .container {
    width: 100%;
  }

  .emoji {
    font-size: 40px;
  }

  .plus {
    font-size: 20px;
  }
}
