/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

body {
  background: var(--bgGeneral);
  font-family: poppins, sans-serif;
  font-weight: 400;
  font-style: normal;
}

/* hiden scrollbar for Chrome, Safari, and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* hiden scrollbar for Firefox */
html {
  scrollbar-width: none;
}

/* hiden scrollbar for Edge, IE */
body {
  -ms-overflow-style: none;
}

p {
  font-family: poppins, sans-serif;
  font-weight: 300;
  font-style: normal;
}
