.term_page {
  padding: 20px;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.term_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 120px 60px 50px 20px;
  /*padding top right bottom left*/
}

.term_title_container {
  height: auto;
  width: auto;
}

.term_title {
  line-height: 1em;
  height: auto;
  width: auto;
  font-size: 40px;
  font-weight: 500;
}

.term_subtitle {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  color: #c4c4c4;
}

.term_text_container {
  margin-top: 20px;
}

.section_text {
  padding-top: 10px;
  padding-bottom: 10px;
}

.section_subheadline {
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 15px;
}

.term_paragraph {
  color: #c4c4c4;
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.term_point {
  padding: 10px;
  margin-left: 20px;
  color: #c4c4c4;
  font-size: 15px;
}

@media screen and (max-width: 768px) {
  .term_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 70px 10px 70px 10px;
    /*padding top right bottom left*/
  }
}

@media only screen and (max-width: 450px) {
  .term_page {
    padding: 7px;
    z-index: 1;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .term_container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 100px 0px 100px 0px;
    /*padding top right bottom left*/
  }

  .term_info_container {
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .term_title_container {
    height: auto;
    width: auto;
    margin-bottom: 8%;
  }

  .term_title {
    line-height: 1em;
    height: auto;
    width: auto;
    font-size: 40px;
    font-weight: 500;
  }
  .term_text_container {
    margin-top: 8%;
  }
}
